<template lang="pug">
include ../pug/svg
dt(:class="{open:item.open}")
	button(type="button" @click="toggle")
		span {{item.term}}
		+svg(svg-filename="iconArrow")
transition(name="dd" @enter="startTransition" @after-enter="endTransition" @before-leave="startTransition" @after-leave="endTransition")
	dd(v-show="item.open")
		div(v-html="item.description")
</template>

<script>
export default {
	name: 'AccordionItem',
	emits: ['update:item'],
	props: ['item', 'dlId'],
	methods: {
		toggle() {
			this.$emit('update:item', this.item);		
		},
		startTransition(el) {
			el.style.height = el.scrollHeight + 'px';
		},
		endTransition(el) {
			el.style.height = '';
		},
	}
};
</script>

<style lang="scss" scoped>
.dd-enter-active,
.dd-leave-active {
	will-change: height;
	transition: height .3s ease-in-out;
}
.dd-enter-from,
.dd-leave-to {
	height: 0 !important;
}
</style>