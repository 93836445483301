<template lang="pug">
dl(:id="id" role="presentation")
	AccordionItem(v-for="item in items" :key="item.id" :item="item" @update:item="updateItem" :dlId="id")
</template>

<script>
import AccordionItem from '../components/AccordionItem';

export default {
	name: 'Accordion',
	components: {
		AccordionItem,
	},
	emits: ['update:items'],
	props: {
		id: {
			type: String,
		},
		items: {
			type: Array,
			required: true
		},
		multiple: {
			type: Boolean,
			default: false
		}	
	},
	methods: {
		updateItem(item) {
			if (this.multiple) {
				item.open = !item.open;
				
			} else {
				for (let i of this.items) {
					i.open = (i.id === item.id) ? !i.open : false;
				}
			}
		},
	},
};
</script>

<style lang="scss">
dl {
	display: block;	
	width: 100%;
	border-radius: 5px;
	background-color: #FFF;
	text-align: left;
	box-shadow: 0 2px 10px rgb(#000, .2);
	dt {
		border-bottom: 1px solid rgba(#000, .1);
	}
	dd:last-child {
		border-radius: 5px;
	}
	dt.open {
		svg {
			transform: rotate(270deg);					
		}
	}
	dd {
		overflow: hidden;
		background: #F5F5F5;
		>div {
			border-bottom: 1px solid rgba(#000, .1);
			padding: 40px 20px;
		}
	}
	dt {
		button {
			display: flex;
			justify-content: space-between;
			align-items: center;			
			width: 100%;
			min-height: 50px;
			text-align: left;
			font-size: 1.8rem;
			color: $green;
			background: transparent;
		}
		span {
			padding: 0 20px;
		}
		svg {
			margin-right: 6px;
			transform: rotate(90deg);
		}
	}
	h6 {
		font-size: 18px;
		margin-bottom: 10px;
	}
	address {
		font-style: normal;
		margin-bottom: 30px;
	}
}	
</style>