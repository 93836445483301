<template lang="pug">
main#about
	div
		section
			hgroup
				h2 Introducing
				h1 The Record Republic

			p The Record Republic is a vinyl shop with a twist - along with <strong>new releases</strong>, we will be commissioning <strong>rare and exclusive vinyl</strong> from some historic label catalogues as well as the freshest cuts from the here and now!

			p We will be giving artists and labels a platform to <strong>create and sell vinyl releases</strong>, whilst we look after the whole process from manufacture to delivery. All this with the potential of <strong>little to no upfront costs</strong>.

			h3.strap Shop <em>/</em> Fund <em>/</em> Create

		section
			h2 Terms of Sale
			
			Accordion(id="dl1" :items="accordionItems")
			
		section
			h2 So that's us. What about you?
			p We love to hear your about experiences with our brand and products first-hand. So don't be a stranger. 
			p Follow our social channels or email <a href="mailto:hq@therecordrepublic.com">hq@therecordrepublic.com</a> anytime.
</template>

<script>
import Accordion from '../components/Accordion';

export default {
	name: 'About',
	components: {
		Accordion,
	},
	data() {
		const accordionItems = [
			{
				id: 1,
				open: false,
				term: 'General',
				description: `
				<p>The creation of an account is not a requirement to purchase any of the items on our web store, however, creating one will allow you to see your order history with us.</p>
<p>Once an order has been placed and payment has been confirmed your personal details will be securely processed by our partners, to allow the order to be fulfilled. These partners are Stripe, who process the payment and UK Pick and Pack, who pick and fulfil the order to you.</p>
				`
			},
			{
				id: 2,
				open: false,
				term: 'Order Processing and Shipping',
				description: `
				<p>Shipping charges are based on the total weight of the products and the destination. The total charges for shipping are calculated at the moment you click a product in your basket. For an indication of the shipping charges of your country, please contact us.</p>
<p>All orders within the UK will be fulfilled using a 48 hour tracked service for up to 2kg. Above 2kg and up to 30kg, UK orders will be fulfilled using a 24 hour tracked service.</p>
<p>All orders outside of the UK will use the most cost effective and secure service available to us, however due to the unstable nature of worldwide services following events like Brexit and the COVID pandemic, we withhold the right to update these services with no notice and therefore, you may receive your order via a courier which was not stated on the website.</p>
<p>In the event this service costs more than the amount taken, The Record Republic will not make any further payment charges to the payment method supplied.
The fulfilment and processing for international orders will be completed by UK Pick & Pack.</p>
<p>If you place an order from outside of the UK, which now includes all EU countries, UK VAT will not be added to your order. Your specific country's VAT will not be added and therefore, by placing an order, you agree that you are wholly responsible for paying VAT at your country’s rate, where applicable, as well as any extra charges levied by the courier due to any other circumstances.</p>
<p>The Record Republic is not liable for any further costs associated with the order.</p>
<p>The Record Republic is required by law to declare an accurate monetary value on all non-UK shipments. We are unable to accept any requests to suppress the value of an order on the customs sticker.</p>
				`
			},
			{
				id: 3,
				open: false,
				term: `In Stock and Pre-Order items`,
				description: `
				<p>Please be aware that if you order both an in stock item and a pre-order item, our system will total the weight and charge you the appropriate amount of shipping. This means you will be charged a single shipping fee and as such, the order will only be processed by our fulfilment company, once the pre-order stock has been received and is ready to process.</p>
<p>This could mean a delay of your in stock item by a substantial time, depending on the pre-order time frame.</p>
<p>If you do not want to wait and therefore pay for combined shipping, please purchase the items separately.</p>
<p>We cannot combine shipping at a later date. If you purchase a pre-order item and later, want to make a purchase of another pre-order item they will be treated as 2 separate orders. We will not be able to respond to requests asking for orders to be combined.</p>
				`
			},
			{
				id: 4,
				open: false,
				term: `Timescales`,
				description: `
				<p>We will endeavour to pick and process your order within 3 working days of the order confirmation via the web store. In the event that your order is placed out of working hours, this will be picked up the next working day, which may be a Monday, if placed after 5pm on Friday.</p>
<p>Please be aware that UK national holidays may mean your order is not processed until Tuesday, upon returning.</p>
<p>International shipping timescales are subject to change and depend on the destination country. Unfortunately in the current climate these are highly changeable, but all efforts will be taken to fulfil orders in a timely manner.</p>
				`
			},
			{
				id: 5,
				open: false,
				term: `Stock`,
				description: `
				<p>All stock is subject to availability and while we have taken every care to not allow a release to enter a basket if it is out of stock, systems can, from time to time, get out of sync. Should your order be out of stock and payment taken, we will refund your payment as soon as possible.</p>
				`
			},
			{
				id: 6,
				open: false,
				term: `Payment Methods`,
				description: `
				<p>The Record Republic accepts payment from a variety of card types, including Visa, Mastercard, American Express, Discover and Diners.</p>
<p>More and more payment methods are added regularly and you can find these payment methods by clicking on your country of residency in the page where you submit your credit card details.</p>
				`
			},
			{
				id: 7,
				open: false,
				term: `Prices and Donations`,
				description: `
				<p>The prices displayed on the website are subject to UK VAT. VAT will be calculated and added onto the sub-total in your basket.</p>
<p>VAT is not added to any donations. These donations are subject to the same payment processing fees as any other payment once the payment has been made, but VAT will not be added to donation amounts within the basket.</p>
				`
			},
			{
				id: 8,
				open: false,
				term: `Returns and Exchanges`,
				description: `
				<p>We love vinyl, but it has its downsides and is a fickle medium by its very nature. We make every effort to transport and store our vinyl with as much protection as possible however, we are unable to accept returns on vinyl which is warped but does not affect playback, or where damage has occurred on the packaging in transit.</p>
<p>If your item shows excessive damage or damage that affects the playback of the record, we will be happy to replace or refund the order.</p>
<p>Please contact us on the details below within 14 days, including pictures of the damage and a brief description, we will then respond with a returns label and number which must be clearly marked on your packaging.</p>
<p>The return must be sent in appropriate packaging. If an item is received and has clearly not been packaged to a satisfactory standard, we reserve the right to reject the return.</p>
<p>Once your return has been confirmed as received, it will be processed as quickly as possible and a replacement item sent, if a replacement was requested and stock is available, or a refund processed if requested or where stock is not available.</p>
<p>Please do not send unsolicited returns to us, without having your initial email confirmed and a returns label / number sent. We cannot be held liable for any items returned outside of this process.</p>
				`
			},
			{
				id: 9,
				open: false,
				term: `Contacting Us`,
				description: `
				<p>If you would like to contact us for any reason, please use the methods provided here.</p>
<h6>By Email</h6>
<p><a href="mailto:hq@therecordrepublic.com">hq@therecordrepublic.com</a></p>
<h6>By Post</h6>
<address>The Record Republic Ltd<br> 44 Orange Row<br> Brighton<br> United Kingdom<br> BN1 1UQ</address>
<p>We will endeavour to reply within 48 hours of receiving your enquiry.</p>
				`
			},
			{
				id: 10,
				open: false,
				term: `Company Information`,
				description: `
				<p>The Record Republic Ltd is a company registered in England and Wales.</p>
<h6>Company Number</h6>
<p>375140309</p>
<h6>Registered Address</h6>
<address>The Record Republic Ltd<br> 44 Orange Row<br> Brighton<br> United Kingdom<br> BN1 1UQ</address>
<h6>VAT Number</h6>
<p>375140309</p>
<h6>Email Address</h6>
<p><a href="mailto:hq@therecordrepublic.com">hq@therecordrepublic.com</a></p>
				`
			},
		];
		
		return {	
			accordionItems,
		}
	},
};
</script>

<style lang="scss">
#about {
	>div {
		display: flex;
		justify-content: center;
		padding: 80px 40px 40px 40px;
		flex-direction: column;
		align-items: center;
		max-width: 900px;
		margin: auto;
		text-align: center;		
	}
	section {
		width: 100%;
		margin-bottom: 50px; // totals to 80px
	}
	h2,
	strong {
		font-family: 'SansBold';
	}
	h2 {
		margin-bottom: 30px;
		font-size: 1.8rem;
		text-transform: uppercase;		
	}
	hgroup {
		h2 {
//			font-family: 'FreeSansBold';
			font-size: 1.4rem;
			text-transform: uppercase;
			letter-spacing: .2em;
			margin: 0;
		}
		h1 {
			font-size: 3rem;
			margin-bottom: 30px;	
		}
	}
	p {
		line-height: 1.4;
//		text-align: left;
	}
	p,
	ul,
	ol
	dl,
	.strap {
		&:not(:last-child) {
			margin-bottom: 30px;
		}		
	}
	h3.strap {
		font-size: 22px;
		text-transform: uppercase;
		letter-spacing: .2em;
		>em {
			display: inline-block;
			font-style: normal;
			transform: scale(2);
			font-family: sans-serif;
			font-weight: 100;
			transform: translateY(-2px) scale(1.6);			
		}
	}
	a {
		color: $green;
		&:hover {
			color: $grey;
		}
	}
}
</style>